import React, { useEffect } from "react";
import { Link } from "gatsby";
import "../../../styles/cookie.css";

const Cookie = ({ props }) => {
    const getCookie = cname => {
        const name = `${cname}=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    };
    const setCookie = (cname, cookieValue, expireDay) => {
        let d = new Date();
        d.setTime(d.getTime() + expireDay * 24 * 60 * 60 * 1000);
        let expires = `expires=${d.toUTCString()}`;
        document.cookie = `${cname}=${cookieValue};${expires};path=/`;
    };
    // Must run under useEffect check https://www.gatsbyjs.org/docs/debugging-html-builds/
    useEffect(() => {
        if (!document.querySelector(".cookie-alert")) {
            return;
        }
        // Show the alert if we cant find the "acceptCookies" cookie
        if (!getCookie("acceptCookies")) {
            document.querySelector(".cookie-alert").classList.add("show");
        }
        document.querySelector(".accept-cookies").addEventListener("click", function() {
            setCookie("acceptCookies", true, 1);
            document.querySelector(".cookie-alert").classList.remove("show");
        });
    });
    return (
        <div className="text-center cookie-alert" role="alert">
            Kami menggunakan cookies untuk pengalaman anda berselancar di situs kami.&nbsp;
            <Link to={"/kebijakan-privasi"}>Pelajari</Link>
            <button type="button" className="btn btn-primary btn-sm accept-cookies">
                Setuju
            </button>
        </div>
    );
};

export default Cookie;
