/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import Header from "./Navigation/header";
import CookieAlert from "./Layout/CookieAlert/cookie";
import Footer from "./Layout/Footer/footer";
import ToTop from "./Layout/ToTop/totop";
import "bootstrap/dist/css/bootstrap.min.css";
import "aos/dist/aos.css";
import "../styles/layout.css";
// put custom css overriding bootstrap here

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);
    return (
        <>
            <Header siteTitle={data.site.siteMetadata.title} />
            <main role="main">{children}</main>
            <Footer />
            <ToTop />
            <CookieAlert />
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
