import React from "react";
import { Link } from "gatsby";
import Nav from "react-bootstrap/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import "../../styles/desktopNav.css";

class DesktopHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scrolled: false,
            setScrolled: false,
        };
        this.handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        // console.log("desktop nav mounted");
        // NavigationLinks.outdoor.map(x => console.log(x));
        document.addEventListener("scroll", this.handleScroll, {
            passive: true,
        });
    }
    componentWillUnmount() {
        document.removeEventListener("scroll", this.handleScroll);
        // console.log("desktop nav unmounted");
    }

    handleScroll() {
        const isScrolled = window.scrollY > 100;
        // TODO Fix this loop, it keeps working even it has been scrolled once
        if (isScrolled !== this.state.scrolled) {
            this.setState({ setScrolled: !this.state.scrolled });
            if (
                !document
                    .getElementById("header")
                    .classList.contains("header-scrolled")
            ) {
                document
                    .getElementById("header")
                    .classList.add("header-scrolled");
                // console.log("scroll class added");
            }
            // console.log("disable");
        }
    }
    render() {
        return (
            <>
                <nav className="nav-menu d-none d-lg-block">
                    <ul>
                        <li className="active">
                            <Link to={"/"}>Beranda</Link>
                        </li>
                        <li className="drop-down">
                            <Nav.Link>Produk</Nav.Link>
                            <ul>
                                <li className="drop-down">
                                    <Nav.Link>
                                        Indoor Display Series&nbsp;
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            fixedWidth={true}
                                        />
                                    </Nav.Link>
                                    <ul>
                                        <li>
                                            <Link to={"/produk/indoor/P1.25"}>
                                                Indoor P1.25
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/produk/indoor/P1.53"}>
                                                Indoor P1.53
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/produk/indoor/P1.83"}>
                                                Indoor P1.83
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/produk/indoor/P1.86"}>
                                                Indoor P1.86
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/produk/indoor/P1.667"}>
                                                Indoor P1.667
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/produk/indoor/P1.904"}>
                                                Indoor P1.904
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/produk/indoor/P2.5"}>
                                                Indoor P2.5
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/produk/indoor/P3.0"}>
                                                Indoor P3.0
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/produk/indoor/P4"}>
                                                Indoor P4
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/produk/indoor/P5"}>
                                                Indoor P5
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="drop-down">
                                    <Nav.Link>
                                        Outdoor Display Series&nbsp;
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            fixedWidth={true}
                                        />
                                    </Nav.Link>
                                    <ul>
                                        <li>
                                            <Link to={"/produk/outdoor/P5"}>
                                                Outdoor P5
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/produk/outdoor/P6"}>
                                                Outdoor P6
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/produk/outdoor/P8"}>
                                                Outdoor P8
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/produk/outdoor/P10"}>
                                                Outdoor P10
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="drop-down">
                                    <Nav.Link>
                                        Small Pixel Pitch Indoor Series&nbsp;
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            fixedWidth={true}
                                        />
                                    </Nav.Link>
                                </li>
                                <li className="drop-down">
                                    <Nav.Link>
                                        Rental Indoor Series&nbsp;
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            fixedWidth={true}
                                        />
                                    </Nav.Link>
                                </li>
                                <li className="drop-down">
                                    <Nav.Link>
                                        Rental Outdoor Series&nbsp;
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            fixedWidth={true}
                                        />
                                    </Nav.Link>
                                </li>
                                <li className="drop-down">
                                    <Nav.Link>
                                        Outdoor Stadium Series&nbsp;
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            fixedWidth={true}
                                        />
                                    </Nav.Link>
                                </li>
                                <li className="drop-down">
                                    <Nav.Link>
                                        Interactive LED Floor Lighting&nbsp;
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            fixedWidth={true}
                                        />
                                    </Nav.Link>
                                </li>
                                <li className="drop-down">
                                    <Nav.Link>
                                        Transparent Indoor LED Series&nbsp;
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            fixedWidth={true}
                                        />
                                    </Nav.Link>
                                    <ul>
                                        <li>
                                            <Link
                                                to={
                                                    "/produk/transparent-indoor-led/P3.91"
                                                }
                                            >
                                                Transparent Indoor P3.91
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={
                                                    "/produk/transparent-indoor-led/P7.81"
                                                }
                                            >
                                                Transparent Indoor P7.81
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={
                                                    "/produk/transparent-indoor-led/P10.41"
                                                }
                                            >
                                                Transparent Indoor P10.41
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="drop-down">
                                    <Nav.Link>
                                        Transparent Outdoor LED Series&nbsp;
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            fixedWidth={true}
                                        />
                                    </Nav.Link>
                                    <ul>
                                        <li>
                                            <Link
                                                to={
                                                    "/produk/transparent-outdoor-led/P3.91"
                                                }
                                            >
                                                Transparent Outdoor P3.91
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={
                                                    "/produk/transparent-outdoor-led/P7.81"
                                                }
                                            >
                                                Transparent Outdoor P7.81
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={
                                                    "/produk/transparent-outdoor-led/P10.41"
                                                }
                                            >
                                                Transparent Outdoor P10.41
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={
                                                    "/produk/transparent-outdoor-led/P15.6"
                                                }
                                            >
                                                Transparent Outdoor P15.6
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={
                                                    "/produk/transparent-outdoor-led/P16.6"
                                                }
                                            >
                                                Transparent Outdoor P16.6
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={
                                                    "/produk/transparent-outdoor-led/P25"
                                                }
                                            >
                                                Transparent Outdoor P25
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={
                                                    "/produk/transparent-outdoor-led/P31.25"
                                                }
                                            >
                                                Transparent Outdoor P31.25
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="drop-down">
                                    <Nav.Link>
                                        Controller&nbsp;
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            fixedWidth={true}
                                        />
                                    </Nav.Link>
                                    <ul>
                                        <li className="drop-down">
                                            <a href="#">Multimedia</a>
                                            <ul>
                                                <li>
                                                    <Link
                                                        to={
                                                            "/produk/controller/multimedia/huidu-hd-a6"
                                                        }
                                                    >
                                                        Huidu HD-A6
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to={
                                                            "/produk/controller/multimedia/listen-ls-q3c"
                                                        }
                                                    >
                                                        Listen LS-Q3C
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to={
                                                            "/produk/controller/multimedia/novastar-tb6"
                                                        }
                                                    >
                                                        Novastar TB6
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="drop-down">
                                            <a href="#">Video processor</a>
                                            <ul>
                                                <li>
                                                    <Link
                                                        to={
                                                            "/produk/controller/videoprocessor/colorlight-x16"
                                                        }
                                                    >
                                                        Colorlight X16
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to={
                                                            "/produk/controller/videoprocessor/listen-vp1000"
                                                        }
                                                    >
                                                        Listen VP1000
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to={
                                                            "/produk/controller/videoprocessor/listen-vp9000"
                                                        }
                                                    >
                                                        Listen VP9000
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to={
                                                            "/produk/controller/videoprocessor/novastar-j6"
                                                        }
                                                    >
                                                        Novastar j6
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to={
                                                            "/produk/controller/videoprocessor/novastar-mctrl600"
                                                        }
                                                    >
                                                        Novastar MCTRL600
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to={
                                                            "/produk/controller/videoprocessor/novastar-vx4s"
                                                        }
                                                    >
                                                        Novastar VX4S
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to={
                                                            "/produk/controller/videoprocessor/novastar-vx6s"
                                                        }
                                                    >
                                                        Novastar VX6S
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to={
                                                            "/produk/controller/videoprocessor/rgblink-venus-x1"
                                                        }
                                                    >
                                                        RGBlink X1
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to={
                                                            "/produk/controller/videoprocessor/vdwall-lvp615s"
                                                        }
                                                    >
                                                        VDWALL LVP615S
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li className="drop-down">
                                    <Nav.Link>
                                        Interactive Smart Board&nbsp;
                                    </Nav.Link>
                                    <ul>
                                        <li className="drop-down">
                                            <Link
                                                to={
                                                    "/produk/interactive-smart-board/mx-series"
                                                }
                                            >
                                                MX Series
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        {/*<li className="drop-down"><a href="">Drop Down</a>*/}
                        {/*    <ul>*/}
                        {/*        <li className="drop-down"><a href="#">Drop Down 2</a>*/}
                        {/*            <ul>*/}
                        {/*                <li><a href="#">Deep Drop Down 1</a></li>*/}
                        {/*                <li><a href="#">Deep Drop Down 2</a></li>*/}
                        {/*                <li><a href="#">Deep Drop Down 3</a></li>*/}
                        {/*                <li><a href="#">Deep Drop Down 4</a></li>*/}
                        {/*                <li><a href="#">Deep Drop Down 5</a></li>*/}
                        {/*            </ul>*/}
                        {/*        </li>*/}
                        {/*        <li><a href="#">Drop Down 3</a></li>*/}
                        {/*        <li><a href="#">Drop Down 4</a></li>*/}
                        {/*        <li><a href="#">Drop Down 5</a></li>*/}
                        {/*    </ul>*/}
                        {/*</li>*/}
                        <li className="drop-down">
                            <Link to={"/portofolio"}>Portofolio</Link>
                        </li>
                        <li className={"drop-down"}>
                            <Nav.Link>Tentang Kami</Nav.Link>
                            <ul>
                                <li>
                                    <Link to={"/tentang-kami"}>
                                        Profil Perusahaan
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/sertifikat-dan-penghargaan"}>
                                        Sertifikat
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </>
        );
    }
}

export default DesktopHeader;
