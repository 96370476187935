import React from "react";
import { Link } from "gatsby";
import Nav from "react-bootstrap/Nav";
import "../../styles/mobileNav.css";

class MobileHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleDropdown = this.handleDropdown.bind(this);
    }

    componentDidMount() {
        document.body.classList.add("mobile-nav-active");
        console.log("mobile navigation mounted");
    }
    componentWillUnmount() {
        document.body.classList.remove("mobile-nav-active");
        console.log("mobile navigation unmounted");
    }
    handleDropdown() {
        console.log("open submenu");
        // document
        //     .querySelector(".mobile-nav li .drop-down")
        //     .classList.add("active");
        // document.querySelector(".mobile-nav li .drop-down ul").style.display =
        //     "block";
    }

    render() {
        return (
            <>
                <nav className="mobile-nav d-lg-none">
                    <ul>
                        <li className="active">
                            <Link to={"/"}>Beranda</Link>
                        </li>
                        <li className="drop-down active">
                            <Nav.Link>Produk</Nav.Link>
                            <ul style={{ display: "block" }}>
                                {/*TODO: Penyewaan belum selesai*/}
                                {/*<li>*/}
                                {/*    <Link to={"/penyewaan"}>Penyewaan</Link>*/}
                                {/*</li>*/}
                                <li className="drop-down">
                                    <Nav.Link>
                                        Indoor Display&nbsp;
                                    </Nav.Link>
                                    <ul style={{ display: "block" }}>
                                        <li>
                                            <Link to={"/produk/indoor/P1.25"}>
                                                Indoor P1.25
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/produk/indoor/P1.53"}>
                                                Indoor P1.53
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/produk/indoor/P1.83"}>
                                                Indoor P1.83
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/produk/indoor/P1.86"}>
                                                Indoor P1.86
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/produk/indoor/P1.667"}>
                                                Indoor P1.667
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/produk/indoor/P1.904"}>
                                                Indoor P1.904
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/produk/indoor/P2.5"}>
                                                Indoor P2.5
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/produk/indoor/P3.0"}>
                                                Indoor P3.0
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/produk/indoor/P4"}>
                                                Indoor P4
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/produk/indoor/P5"}>
                                                Indoor P5
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="drop-down">
                                    <Nav.Link>
                                        Outdoor Display&nbsp;
                                    </Nav.Link>
                                    <ul style={{ display: "block" }}>
                                        <li>
                                            <Link to={"/produk/outdoor/P5"}>
                                                Outdoor P5
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/produk/outdoor/P6"}>
                                                Outdoor P6
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/produk/outdoor/P8"}>
                                                Outdoor P8
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/produk/outdoor/P10"}>
                                                Outdoor P10
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        {/*TODO: Ini bagian daftar proyek yang sudah dikerjakan*/}
                        {/*<li className="drop-down">*/}
                        {/*    <Nav.Link>Proyek</Nav.Link>*/}
                        {/*    <ul>*/}
                        {/*        <li>*/}
                        {/*            <Link to={"/penyewaan"}>Proyek Sewa</Link>*/}
                        {/*        </li>*/}
                        {/*        <li className="drop-down">*/}
                        {/*            <Nav.Link>Proyek Indoor Display</Nav.Link>*/}
                        {/*            <ul>*/}
                        {/*                <li>*/}
                        {/*                    <Link to={"/produk/P2.5-indoor"}>*/}
                        {/*                        Indoor P2.5*/}
                        {/*                    </Link>*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    <Link to={"/produk/P3.0-indoor"}>*/}
                        {/*                        Indoor P3.0*/}
                        {/*                    </Link>*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    <Link to={"/produk/P4-indoor"}>*/}
                        {/*                        Indoor P4*/}
                        {/*                    </Link>*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    <Link to={"/produk/P5-indoor"}>*/}
                        {/*                        Indoor P5*/}
                        {/*                    </Link>*/}
                        {/*                </li>*/}
                        {/*            </ul>*/}
                        {/*        </li>*/}
                        {/*        <li className="drop-down">*/}
                        {/*            <Nav.Link>Proyek Outdoor Display</Nav.Link>*/}
                        {/*            <ul>*/}
                        {/*                <li>*/}
                        {/*                    <Link to={"/produk/P5-outdoor"}>*/}
                        {/*                        Outdoor P5*/}
                        {/*                    </Link>*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    <Link to={"/produk/P6-outdoor"}>*/}
                        {/*                        Outdoor P6*/}
                        {/*                    </Link>*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    <Link to={"/produk/P8-outdoor"}>*/}
                        {/*                        Outdoor P8*/}
                        {/*                    </Link>*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    <Link to={"/produk/P10-outdoor"}>*/}
                        {/*                        Outdoor P10*/}
                        {/*                    </Link>*/}
                        {/*                </li>*/}
                        {/*            </ul>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</li>*/}
                        <li className={"drop-down"}>
                            <Nav.Link>Tentang Kami</Nav.Link>
                            <ul>
                                <li>
                                    <Link to={"/tentang-kami"}>
                                        Profil Perusahaan
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/sertifikat-dan-penghargaan"}>
                                        Sertifikat
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
                <div
                    className="mobile-nav-overly"
                    style={{ display: "block" }}
                />
            </>
        );
    }
}

export default MobileHeader;
