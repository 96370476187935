import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { Link } from "gatsby";
import Logo from "../../images/logo-ilife.svg";
import MobileHeader from "../Navigation/mobileHeader";
import DesktopHeader from "../Navigation/desktopHeader";
import NavigationLinks from "../../content/navigation/nav.json";
import "../../styles/header.css";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isMobile: false };
        this.handleMobile = this.handleMobile.bind(this);
    }
    handleMobile() {
        if (this.state.isMobile) {
            this.setState({ isMobile: false });
        }
        if (!this.state.isMobile) {
            this.setState({ isMobile: true });
        }
    }
    renderDesktop() {
        return <DesktopHeader navigation={NavigationLinks} />;
    }
    renderMobile() {
        return <MobileHeader navigation={NavigationLinks} />;
    }
    render() {
        let renderHeader;
        if (this.state.isMobile) {
            renderHeader = this.renderMobile();
        } else {
            renderHeader = this.renderDesktop();
        }
        return (
            <>
                <button
                    aria-label={"menu button"}
                    type="button"
                    className="mobile-nav-toggle d-lg-none"
                    onClick={this.handleMobile}
                >
                    <FontAwesomeIcon
                        {...(this.state.isMobile
                            ? { icon: faTimes }
                            : { icon: faBars })}
                    />
                </button>
                <header
                    id="header"
                    className="fixed-top d-flex align-items-center"
                >
                    <div className="container d-flex align-items-center">
                        <div className="logo mr-auto">
                            <Link to={"/"} aria-label={"iLife logo"}>
                                <img src={Logo} alt="Logo iLife" />
                            </Link>
                        </div>
                        {renderHeader}
                    </div>
                </header>
            </>
        );
    }
}

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header;
