import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons/faWhatsapp";
import "../../../styles/footer.css";

const Footer = () => (
    <footer id="footer">
        <div className="footer-top">
            <Container>
                <Row>
                    <div className="col-lg-3 col-md-6 footer-contact">
                        <h3>iLife</h3>
                        <p>
                            CV. Anugerah Terang Dunia
                            <br />
                            Jl. Kol. Sunandar No. 31
                            <br />
                            Blora, Jawa Tengah 58214
                            <br />
                            Indonesia
                            <br />
                            <strong>Kantor:</strong> +62296-5301042
                            <br />
                            <strong>WhatsApp:</strong> +62895-06017899
                            <br />
                            <strong>Telepon:</strong> +62812–15567626
                            <br />
                            <strong>Email:</strong> info@ilife.id
                            <br />
                        </p>
                    </div>
                    <div className="col-lg-2 col-md-6 footer-links">
                        <h4>Tautan</h4>
                        <ul>
                            <li>
                                <FontAwesomeIcon icon={faChevronRight} />
                                &nbsp;
                                <Link to={"/"}>Beranda</Link>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faChevronRight} />
                                &nbsp;
                                <Link to={"/tentang-kami"}>Tentang Kami</Link>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faChevronRight} />
                                &nbsp;
                                <Link to={"/penyewaan"}>Layanan</Link>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faChevronRight} />
                                &nbsp;
                                <Link to={"/kebijakan-privasi"}>
                                    Kebijakan Privasi
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-2 col-md-6 footer-links">
                        <h4>Layanan Kami</h4>
                        <ul>
                            <li>
                                <FontAwesomeIcon icon={faChevronRight} />
                                &nbsp;
                                <Link to={"/"}>Videotron</Link>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faChevronRight} />
                                &nbsp;
                                <Link to={"/"}>Running Text</Link>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faChevronRight} />
                                &nbsp;
                                <Link to={"/penyewaan"}>Penyewaan</Link>
                            </li>
                        </ul>
                    </div>
                    {/*TODO: Fix the subscribe button*/}
                    <div className="col-lg-5 col-md-6 footer-newsletter">
                        <h4>Bergabung buletin kami</h4>
                        <p>
                            Mari berlangganan buletin promosi kami dengan
                            menghubungi kami untuk mendapatkan tawaran terbaik
                        </p>
                        {/*<Form className={"form-inline"} method={"post"} netlify-honeypot={"bot-field"} data-netlify={"true"}>*/}
                        {/*    <Form.Group>*/}
                        {/*        <label htmlFor={"subscribe-email"} className={"sr-only"}>*/}
                        {/*            Email*/}
                        {/*        </label>*/}
                        {/*        <input id={"subscribe-email"} aria-label={"email"} type="email" name="email" placeholder={"Email"} className={"form-control"} />*/}
                        {/*    </Form.Group>*/}
                        {/*    <Button type={"submit"}>Berlangganan</Button>*/}
                        {/*</Form>*/}
                    </div>
                </Row>
            </Container>
        </div>
        <Container className="d-md-flex py-4">
            <div className="mr-md-auto text-center text-md-left">
                <div className="copyright">
                    &copy;&nbsp;
                    <strong>
                        <span>CV. Anugerah Terang Dunia</span>
                    </strong>
                    . Hak cipta dilindungi undang-undang
                </div>
                <div className="credits">
                    Built with lotsa{" "}
                    <span role="img" aria-label="coffee">
                        ☕
                    </span>{" "}
                    by{" "}
                    <a
                        href="https://twitter.com/simplyeazy"
                        aria-label="Twitter"
                        target={"_blank"}
                        rel={"noreferrer noopener"}
                    >
                        simplyeazy
                    </a>
                </div>
            </div>
            <div className="social-links text-center text-md-right pt-3 pt-md-0">
                <a
                    href={"https://instagram.com/ilife_advertising"}
                    aria-label="Instagram"
                    target={"_blank"}
                    rel={"noreferrer noopener"}
                >
                    <FontAwesomeIcon icon={faInstagram} />
                </a>

                <a
                    href={"https://wa.me/6289506017899"}
                    aria-label="WhatsApp"
                    target={"_blank"}
                    rel={"noreferrer noopener"}
                >
                    <FontAwesomeIcon icon={faWhatsapp} />
                </a>
            </div>
        </Container>
    </footer>
);

Footer.propTypes = {
    siteTitle: PropTypes.string,
};

Footer.defaultProps = {
    siteTitle: ``,
};

export default Footer;
