import React, { useEffect, useState } from "react";
import "../../../styles/totop.css";

const ToTop = props => {
    const [showScroll, setShowScroll] = useState(false);
    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 200) {
            setShowScroll(true);
        } else if (showScroll && window.pageYOffset <= 200) {
            setShowScroll(false);
        }
    };
    const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

    useEffect(() => {
        window.addEventListener("scroll", checkScrollTop);
        return () => {
            window.removeEventListener("scroll", checkScrollTop);
        };
    }, [showScroll, setShowScroll]);

    return (
        <button
            className="link-button back-to-top"
            onClick={scrollTop}
            style={{ display: showScroll ? "inline" : "none" }}
        >
            <i className="fas fa-chevron-up" />
        </button>
    );
};

export default ToTop;
